import React, { useState, useEffect } from "react";
import validator from "validator";
import {
  Button,
  Grid,
  Typography,
  Link,
  IconButton,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

// Utilities
import ReCAPTCHA from "react-google-recaptcha";
import { captchaCheck } from "../../APIs/databaseAPI";

// Components
import TextInput from "../../Components/TextInput";
import PopoverComponent from "../../Components/Popover";
import TermsConditionsDialog from "../../Components/TermsConditionsDialog";
import SuccessfulRegisterDialog from "./SuccessfulRegisterDialog";
import Footer from "../../Components/Footer/Footer";
// APIs
import { registerUser, checkEmailIsAvailable } from "../../APIs/databaseAPI";
// Validation
import {
  checkName,
  checkEmail,
  checkCompanyNameAndPosition,
  checkPassword,
} from "./registerValidation";

// Styles
import "./registerStyles.css";

const NoBackgroundIconButton = styled(IconButton)(({ theme }) => ({
  "&&": {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default function RegisterPage() {
  const navigate = useNavigate();
  // Inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  // Button
  const [isAcceptButtonDisabled, setAcceptButtonStatus] = useState(true);

  const nonErrorObject = {
    hasError: false,
    message: "",
  };
  const requiredErrorObject = {
    hasError: true,
    message: "*Required",
  };
  // Error values
  const [emailError, setEmailError] = useState(nonErrorObject);
  const [firstNameError, setFirstNameError] = useState(nonErrorObject);
  const [lastNameError, setLastNameError] = useState(nonErrorObject);
  const [companyNameError, setCompanyNameError] = useState(nonErrorObject);
  const [positionError, setPositionError] = useState(nonErrorObject);
  const [passwordError, setPasswordError] = useState(nonErrorObject);
  const [confirmPasswordError, setConfirmPasswordError] =
    useState(nonErrorObject);

  const [termsError, setTermsError] = useState();
  const [captchaError, setCaptchaError] = useState();

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
    setScroll("paper");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const passwordRequirement = await checkPassword(
      password,
      confirmPassword,
      setPasswordError,
      setConfirmPasswordError
    );
    const firstNameRequirement = await checkName(firstName, setFirstNameError);
    const lastNameRequirement = await checkName(lastName, setLastNameError);
    const emailRequirement = await checkEmail(email, setEmailError);
    const companyRequirement = await checkCompanyNameAndPosition(
      companyName,
      setCompanyName,
      setCompanyNameError
    );
    const positionRequirement = await checkCompanyNameAndPosition(
      position,
      setPosition,
      setPositionError
    );

    // If email passes regex test and validation.
    // Check if email already exists in database
    if (emailRequirement) {
      checkEmailIsAvailable(email, function (response) {
        if (response.status === 400) {
          setEmailError({
            hasError: true,
            message: response.message,
          });
        } else {
          setEmailError({
            hasError: false,
            message: "",
          });
        }
      });
    }

    // Check all inputs pass validation.
    if (
      firstNameRequirement &&
      lastNameRequirement &&
      !emailError.hasError &&
      companyRequirement &&
      positionRequirement &&
      passwordRequirement
    ) {
      // If all inputs pass validation.
      // Check if terms and conditions were read
      // Check if captcha was checked.
      if (isAcceptButtonDisabled) {
        if (isAcceptButtonDisabled) {
          setTermsError("Please read and accept the terms & conditions.");
        } else {
          setTermsError("");
        }
      } else {
        // If both terms&conditions and captcha are false.
        // Register the user.
        if (captchValue) {
          await captchaCheck(captchValue, function (response) {
            const captchaSuccess = response;
            if (captchaSuccess) {
              try {
                setCaptchaError("");
                registerUser(
                  validator.escape(firstName),
                  validator.escape(lastName),
                  validator.escape(validator.normalizeEmail(email)),
                  validator.escape(companyName),
                  validator.escape(position),
                  validator.escape(phone),
                  password,
                  function (response) {
                    const passRegistrationValidation = true;
                    // 400 = Email already exists.
                    // 201 = Success
                    // 500 = Uncaught error
                    if (response.status === 400) {
                      setEmailError({
                        hasError: true,
                        message: response.message,
                      });
                      passRegistrationValidation = false;
                    } else {
                      handleClickSuccessRegisterOpen();
                    }
                  }
                );
              } catch (error) {}
            } else {
              setCaptchaError("You did not pass the reCaptcha.");
            }
          });
        } else {
          setCaptchaError("Please check the reCAPTCHA to proceed.");
        }
      }
    }
  };

  /**
   *
   * @param {String} value The value the captcha when clicked.
   */
  function onCaptchaChange(value) {
    setCaptchaValue(value);
  }

  // Handles the dialog that opens and closes if registration was successful
  const [successRegisterOpen, setSuccessRegisterOpen] = useState(false);

  const handleClickSuccessRegisterOpen = () => {
    setSuccessRegisterOpen(true);
  };

  const handleClickSuccessRegisterClose = () => {
    setSuccessRegisterOpen(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setCompanyName("");
    setPosition("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
  };
  const [captchValue, setCaptchaValue] = useState();
  const handleCaptchaOnChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <div className="registerPage">
      <div className="registerMain">
        <div className="registerContainer">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center  margin-around-2-percent">
                <Typography variant="h4">Registration</Typography>
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center ">
                <TextInput
                  label="First Name"
                  value={firstName}
                  valueChangeHandler={setFirstName}
                  type="text"
                  isRequired={true}
                  error={firstNameError.hasError}
                  helperText={firstNameError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Last Name"
                  value={lastName}
                  valueChangeHandler={setLastName}
                  type="text"
                  isRequired={true}
                  error={lastNameError.hasError}
                  helperText={lastNameError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Email"
                  value={email}
                  valueChangeHandler={setEmail}
                  type="email"
                  isRequired={true}
                  error={emailError.hasError}
                  helperText={emailError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Company Name"
                  value={companyName}
                  valueChangeHandler={setCompanyName}
                  type="text"
                  isRequired={true}
                  error={companyNameError.hasError}
                  helperText={companyNameError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Position"
                  value={position}
                  valueChangeHandler={setPosition}
                  type="text"
                  isRequired={true}
                  error={positionError.hasError}
                  helperText={positionError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center ">
                <TextInput
                  label="Phone (optional)"
                  value={phone}
                  valueChangeHandler={setPhone}
                  type="tel"
                  isRequired={false}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Password"
                  value={password}
                  valueChangeHandler={setPassword}
                  type="password"
                  isRequired={true}
                  error={passwordError.hasError}
                  helperText={passwordError.message}
                  popoverText={[
                    "Must include at least 1 for each of the following.",
                    "An uppercase letter",
                    "A lowercase letter",
                    "A digit",
                    "A special character",
                  ]}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="flex-center">
                <TextInput
                  label="Re-confirm Password"
                  value={confirmPassword}
                  valueChangeHandler={setConfirmPassword}
                  type="password"
                  isRequired={true}
                  popoverText={["Passwords must match."]}
                  error={confirmPasswordError.hasError}
                  helperText={confirmPasswordError.message}
                  inputStyle="registerInput"
                />
              </div>
            </Grid>
            {/* ----- END OF INPUTS ----- */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center">
                <NoBackgroundIconButton onClick={handleClickOpen}>
                  <div className="flex-center">
                    {isAcceptButtonDisabled ? (
                      <CircularProgress color="success" size="25px" />
                    ) : (
                      <CheckCircleOutlineOutlinedIcon color="success" />
                    )}

                    <Link style={{ fontSize: "20px" }}>Terms & Conditions</Link>
                  </div>
                </NoBackgroundIconButton>
                <Typography className="errorMessage" variant="caption">
                  *Required
                </Typography>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center">
                {ReCAPTCHA ? (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleCaptchaOnChange}
                    // ref={(ref) => {
                    //     // Store a reference to the ReCaptcha component
                    //     if (ref) {
                    //         ref.reset(); // Reset the ReCaptcha component when it is mounted
                    //     }
                    // }}
                  />
                ) : (
                  <span>Captcha loading..</span>
                )}
              </div>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center-column errorMessage">
                <Typography variant="caption">{termsError}</Typography>
                <Typography variant="caption">{captchaError}</Typography>
              </div>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="flex-center-column">
                <Button variant="contained" onClick={onSubmit}>
                  Create Account
                </Button>

                <div className="margin-around-2-percent">
                  <Typography>
                    Already have an Account?
                    <Link href="/"> Login here!</Link>
                  </Typography>
                </div>
              </div>
            </Grid>
            {open ? (
              <TermsConditionsDialog
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                scroll={scroll}
                setCreateAccount={setAcceptButtonStatus}
              />
            ) : null}
          </Grid>
          {successRegisterOpen ? (
            <SuccessfulRegisterDialog
              open={successRegisterOpen}
              handleOpen={handleClickSuccessRegisterOpen}
              handleClose={handleClickSuccessRegisterClose}
            />
          ) : null}
          {/* <Button onClick={() => console.log(isAcceptButtonDisabled)}>
            TEST
          </Button> */}
        </div>
        <div className="footerCompanyName footerText white-font loginFooter">
          <div>Powered by</div>

          <IconButton
            className="breakWaterIconButton"
            // onClick={handleBWLogoClick}
          >
            <img
              className="footerBWVantageLogo"
              src="images\BreakwaterVantageLogo .png"
              alt="BWVantage Logo"
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
