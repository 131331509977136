const termPoints = [
  {
    title: "Terms and Conditions",
    points: [
      "Last updated: April 1, 2023",
    ],
  },
  {
    title: "Interpretation",
    points: [
      "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
    ],
  },
  {
    title: "Definitions",
    points: [
      'Company (referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer) refers to Breakwater Vantage Inc.',
      "Service refers to the Website.",
      "You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
      "Website refers to Portal/iOS/Android APPs, accessible from Website or Mobile.",
    ],
  },
  {
    title: "Disclaimer",
    points: [
      "Please read this disclaimer carefully before using any information, tools, or methodologies provided for emission calculations. By accessing or using these resources, you acknowledge that you have read, understood, and agree to be bound by the terms of this disclaimer.",
      "The information contained on the Service is for general information purposes only.",
      "The Company assumes no responsibility for errors or omissions in the contents of the Service.",
      "In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.",
      "By using the emission calculations or estimated methodologies, you agree to indemnify and hold harmless the provider of these resources, its affiliates, officers, employees, subcontractors, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of these resources."
    ],
  },
  {
    title: "Estimated methodology",
    points: [
      "The emission calculations provided on this platform are based on estimated methodologies, which are developed using available scientific research, industry best practices, and commonly accepted emission factors. However, these methodologies may not be exhaustive or universally applicable to every situation or geographical location. They should be considered as a starting point for estimating emissions and should not be considered as a definitive or legally binding measurement."
    ],
  },
  {
    title: "External Links Disclaimer",
    points: [
      "This platform may contain links to third-party websites or resources. We are not responsible for the content or accuracy of any such external sites and do not endorse or guarantee the information, products, or services offered therein."
    ],
  },
  {
    title: "Accuracy and completeness",
    points: [
      "While we strive to provide accurate and up-to-date information, we make no warranties or representations, express or implied, as to the accuracy, completeness, reliability, or suitability of the emission calculations or estimated methodologies. Users are responsible for verifying the accuracy and appropriateness of any calculations or methodologies for their specific circumstances and for obtaining professional advice as necessary.",
    ],
  },
  {
    title: "Fair Use Disclaimer",
    points: [
      "The Company may use copyrighted material which has not always been specifically authorized by the copyright owner.",
      "The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.",
      "The Company believes this constitutes a 'fair use' of any such copyrighted material as provided for in section 107 of the United States Copyright law.",
      "If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.",
    ],
  },
  {
    title: "Views Expressed Disclaimer",
    points: [
      "The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.",
      "Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment.",
      "The Company is not liable for any comment published by users and reserves the right to delete any comment for any reason whatsoever.",
    ],
  },
  {
    title: "No Responsibility Disclaimer ",
    points: [
      "The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers. In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.",
      "Use at Your Own Risk Disclaimer All information in the Service is provided 'as is', with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.",
    ],
  },
  {
    title: "Limitation of liability",
    points: [
      "In no event shall we be liable for any direct, indirect, incidental, consequential, or special damages, including but not limited to loss of data, profits, or goodwill, arising from or in connection with the use of or reliance upon the emission calculations or estimated methodologies."
    ],
  },
  {
    title: "Changes and updates",
    points: [
      "We reserve the right to modify or update the emission calculations or estimated methodologies at any time without notice. It is the user's responsibility to stay informed about any changes and to ensure they are using the most recent version of the resources."
    ],
  },
  {
    title: "Contact Us",
    points: [
      "If you have any questions about this Disclaimer, You can contact Us: By email: info@bwvantage.com",
    ],
  },
];

export { termPoints };
