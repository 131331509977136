import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material/";

import { termPoints } from "./Footer/FooterDialogs/termsPoints";
/**
 *
 * @param {Boolean} open - True/False value that indicates if the dialog should be opened or closed.
 * @param {Function} handleClose - Handler that sets the open state to false.
 * @param {String} scroll - The type of component behavoir the scroll should take. Ex. "Paper"
 * @returns
 */
export default function TermsConditionsDialog({
  open,
  handleClose,
  scroll,
  setCreateAccount,
}) {
  // Will only be false if user scrolls all the way to the bottom AND presses Accept.
  const [isAcceptButtonDisabled, setAcceptButtonStatus] = useState(true);

  // Clicking Accept button will close the dialog and set the state of the CreateAccount in the parent component (RegisterPage).
  const handleAccept = () => {
    setCreateAccount(false);
    handleClose();
  };

  // Reference of the scroll bar.
  const dialogContentRef = useRef(null);

  // Enables the Accept button when the user scrolls to the bottom of the dialog.
  const handleScroll = () => {
    const element = dialogContentRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setAcceptButtonStatus(false);
    }
  };

  // Sets focus accessibility purposes
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle id="scroll-dialog-title">
          <Typography component={"span"} variant="h6">
            Terms & Conditions
          </Typography>
          <Typography sx={{ marginLeft: "2%" }} color={"red"} variant="caption">
            Please read terms & conditions before accepting.
          </Typography>
        </DialogTitle>
        <DialogContent
          ref={dialogContentRef}
          onScroll={handleScroll}
          dividers={scroll === "paper"}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {termPoints.map(function (point, index) {
              return (
                <div key={point + "" + index}>
                  <h4>{point.title}</h4>
                  {point.points.map(function (innerPoint, index) {
                    return <p>{innerPoint}</p>;
                  })}
                </div>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={isAcceptButtonDisabled}
            onClick={handleAccept}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
